/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~animate.css/animate.min.css";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
.rate-button-empty {
  width: 40px;
  min-width: auto !important;
  height: 40px;
  margin: 0 !important;
  background-image: url("./assets/icon/Star-Empty-icon.png");
  background-repeat: no-repeat;
  background-position: center;
  border: none;
}
.rate-button-empty .button-inner {
  visibility: hidden;
}

.rate-button-full {
  width: 40px;
  min-width: auto !important;
  height: 40px;
  margin: 0 !important;
  background-image: url("./assets/icon/Star-Full-icon.png");
  background-repeat: no-repeat;
  background-position: center;
  border: none;
}
.rate-button-full .button-inner {
  visibility: hidden;
}

.alertstar {
  border-radius: 8px;
}
.alertstar .alert-wrapper {
  border-radius: 8px;
}
.alertstar .alert-button-group {
  padding: 0;
  margin-top: 10px;
  flex-direction: row;
  justify-content: space-around;
  flex-direction: row;
  justify-content: space-around;
  display: flex;
  flex-wrap: wrap;
}

/* archivo-regular - latin */
@font-face {
  font-family: "AllRoundGothic";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/All Round Gothic Font/AllRoundGothic-Book.otf"); /* IE9 Compat Modes */
}
@font-face {
  font-family: "AllRoundGothic";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/All Round Gothic Font/AllRoundGothic-Bold.otf"); /* IE9 Compat Modes */
}
@font-face {
  font-family: "AllRoundGothic";
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/All Round Gothic Font/AllRoundGothic-BookOblique.otf"); /* IE9 Compat Modes */
}
* {
  font-family: "AllRoundGothic" !important;
}

progress[value] {
  margin-left: 1px;
  margin-right: 1px;
}

progress[value] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
}

progress[value]::-webkit-progress-bar {
  border-radius: 9px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  background-color: rgba(var(--ion-color-primary-rgb), 0.3);
}

progress[value]::-webkit-progress-value {
  background-color: var(--ion-color-primary);
  border-radius: 9px;
}

progress[value].inhibited {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
}

progress[value].inhibited::-webkit-progress-bar {
  background-color: #f3f3f3;
  border-radius: 9px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

progress[value].inhibited::-webkit-progress-value {
  background-color: #b22527;
  border-radius: 9px;
}

h1 {
  font-family: "Archivo";
  font-weight: 600px;
  font-size: 18px;
}

h2 {
  font-family: "Archivo Narrow";
  font-weight: 500px;
  font-size: 16px;
}

h3 {
  font-family: "Archivo Narrow";
  font-size: 14px;
  font-weight: 400px;
}

h4 {
  font-family: "Archivo Narrow";
  font-size: 12px;
  font-weight: 300px;
}

ion-title {
  color: var(--ion-color-title);
  text-align: center;
  font-family: "Signika";
  font-weight: 700;
}

button span button-inner {
  background: var(--ion-color-exme-dark);
}

.exme-button-full {
  background-color: var(--ion-color-exme-dark);
  font-family: "Signika";
  font-weight: 300;
  color: white;
  border-radius: 25px;
  margin-left: 2px !important;
  margin-right: 0px !important;
}

.exme-button-red {
  background-color: var(--ion-color-exme-red);
  font-family: "Signika";
  font-weight: 300;
  color: white;
  border-radius: 9px;
  margin-left: 2px !important;
  margin-right: 0px !important;
}

.exme-icon-full {
  color: var(--ion-color-exme-dark);
}

.segment-button-checked {
  color: var(--ion-color-primary) !important;
  --color-checked: var(--ion-color-primary);
}

.segment-button {
  color: var(--ion-color-exme-dark);
  border: none;
}

.segment-button-indicator-background {
  background-color: var(--ion-color-exme-dark);
}

body.scanner-active {
  --background: transparent;
  --ion-background-color: transparent;
}

ion-popover {
  --background: rgba(40, 173, 218, 0.6);
  --backdrop-opacity: 0.6;
  --color: white;
  --width: 300px;
}

.v-col-added-modal {
  --height: 50%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.add-col-modal {
  --height: 85%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.v-add-col-toolbar {
  --background: var(--ion-color-primary);
  --color: white;
}

.album-view-modal {
  --height: 90%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}

font {
  color: var(--ion-color-black-white);
}

ion-segment-button {
  --color: var(--ion-color-gray-white);
}

.alert-buttons {
  /*div.alert-wrapper {
      background-color: var(--ion-color-white-gray)
    }*/
}
.alert-buttons [aria-checked=true].sc-ion-alert-md .alert-radio-label.sc-ion-alert-md {
  color: var(--ion-color-black-white);
}
.alert-buttons div.alert-message {
  color: var(--ion-color-light-gray-white);
}

ion-item {
  --highlight-background: var(--ion-color-primary) !important;
}

.message-right > font,
.message > font,
.message-right {
  color: var(--ion-color-inverted-text);
}

ion-menu-button {
  color: var(--ion-color-primary);
}

.qrcode-alert .alert-message {
  text-align: center;
}

.invalid-referral-code-toast {
  z-index: 24000 !important;
}

.header-title {
  max-width: calc(100% - 40px);
}

ion-button.md {
  text-transform: unset;
}

ion-button {
  font-weight: bold;
  font-size: 1rem !important;
  --border-radius: 25px !important;
  height: 45px !important;
  min-width: 45px !important;
}

ion-button.swap-button {
  height: 30px !important;
}

.bold {
  font-weight: bold !important;
}

ion-badge {
  border-radius: 25px;
}

ion-button::part(native) {
  padding: 4px;
}

ion-button.ios {
  min-height: 0px;
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

.ql-editor.ql-blank::before {
  color: #9e9e9e;
  font-style: normal;
}

.ql-tooltip {
  z-index: 1;
}

.ql-editor {
  border: 1px solid #232323;
}

.ql-size-small {
  font-size: 0.75em;
}

.ql-size-large {
  font-size: 1.5em;
}

.ql-size-huge {
  font-size: 2.5em;
}

.ql-editor[contenteditable=false] {
  padding: 0;
  border: 0;
}

.ql-size-huge {
  font-size: var(--dynamic-font-size-huge, 2.5rem) !important;
}

.ql-editor h1 {
  font-size: var(--dynamic-font-size-h1, 2rem) !important;
}